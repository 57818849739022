import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["name", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['input'])
  }, [
    _createElementVNode("label", {
      for: _ctx.name,
      class: _normalizeClass([
        _ctx.disabled ? _ctx.$style['input__label--disabled'] : _ctx.$style['input__label'],
      ])
    }, _toDisplayString(_ctx.label), 11, _hoisted_1),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      name: _ctx.name,
      class: _normalizeClass([
        _ctx.disabled ? _ctx.$style['text-input--disabled'] : _ctx.$style['text-input'],
      ]),
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.inputValue) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChangeValue && _ctx.onChangeValue(...args))),
      disabled: _ctx.disabled
    }, null, 42, _hoisted_2), [
      [_vModelText, _ctx.state.inputValue]
    ]),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.$style['input__error-text'])
        }, _toDisplayString(_ctx.errorMsg), 3))
      : _createCommentVNode("", true)
  ], 2))
}