
import { SelectboxOption } from "@/types";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isShowingDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },

  setup(props, context) {
    const onChangeOption = (option: SelectboxOption): void => {
      context.emit("onChangeOption", option);
    };

    return {
      onChangeOption,
    };
  },
});
