import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.isGameReady
        ? _ctx.$style['room-number-dialog--animated']
        : _ctx.$style['room-number-dialog'],
    ])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['room-number-dialog__label'])
      }, "Room Number", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['room-number-dialog__text'])
      }, _toDisplayString(_ctx.roomNumber), 3)
    ])
  ], 2))
}