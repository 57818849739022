
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    ans: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    const onClickYes = (): void => {
      context.emit("onChangeValue", true);
    };

    const onClickNo = (): void => {
      context.emit("onChangeValue", false);
    };

    return {
      onClickYes,
      onClickNo,
    };
  },
});
