
/*global google*/
import { GameHistory } from "@/types";
import { defineComponent, watch, onMounted, ref } from "vue";

export default defineComponent({
  props: {
    selectedMode: {
      type: String,
      required: true,
    },
    isOwner: {
      type: Boolean,
      required: true,
    },
    isShowingResult: {
      type: Boolean,
      requreid: true,
    },
    isShowingSummary: {
      type: Boolean,
      required: true,
    },
    isNextRoundReady: {
      type: Boolean,
      required: true,
    },
    randomLatLng: {
      type: google.maps.LatLng,
      default: null,
    },
    selectedLatLng: {
      type: google.maps.LatLng,
      default: null,
      required: false,
    },
    selectedLatLngArr: {
      type: Array,
      required: true,
    },
    gameHistory: {
      type: Array,
      required: true,
    },
    distance: {
      type: Number,
      default: null,
    },
    distanceByPlayerArr: {
      type: Array,
      required: true,
    },
    round: {
      type: Number,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
    multiplayerGameSummary: {
      type: Array,
      required: true,
    },
  },

  setup(props, context) {
    let map: google.maps.Map;
    const resultMapRef = ref<HTMLElement>();
    let markers: google.maps.Marker[] = [];
    let polylines: google.maps.Polyline[] = [];

    const putMarker = (position: google.maps.LatLng): void => {
      const marker = new google.maps.Marker({
        position: position,
        map: map,
      });
      markers.push(marker);
    };

    const removeMarkers = (): void => {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];
    };

    const drawPolyline = (
      from: google.maps.LatLng,
      to: google.maps.LatLng
    ): void => {
      const polyline = new google.maps.Polyline({
        path: [from, to],
        strokeColor: "hsl(0, 100%, 63%)",
      });
      polyline.setMap(map);
      polylines.push(polyline);
    };

    const removePolyline = (): void => {
      polylines.forEach((line) => {
        line.setMap(null);
      });
      polylines = [];
    };

    const onClickNextRoundButton = (): void => {
      context.emit("onClickNextRoundButton");
    };

    const onClickViewSummaryButton = (): void => {
      context.emit("onClickViewSummaryButton");
      (props.gameHistory as Array<GameHistory>).forEach((e: GameHistory) => {
        putMarker(e.randomLatLng);
        putMarker(e.selectedLatLng);
        drawPolyline(e.randomLatLng, e.selectedLatLng);
      });
    };

    const onClickPlayAgainButton = (): void => {
      context.emit("onClickPlayAgainButton");
    };

    const onClickExitButton = (): void => {
      context.emit("onClickExitButton");
    };

    const endMultiplayerGame = (): void => {
      context.emit("endMultiplayerGame");
    };

    watch(
      () => props.isShowingResult,
      (newVal: boolean) => {
        if (props.selectedMode === "multiplayer") {
          if (
            newVal &&
            props.randomLatLng &&
            props.selectedLatLngArr.length > 0 &&
            props.distanceByPlayerArr.length > 0
          ) {
            putMarker(props.randomLatLng);
            (props.selectedLatLngArr as google.maps.LatLng[]).forEach(
              (latLng: google.maps.LatLng) => {
                putMarker(latLng);
                drawPolyline(props.randomLatLng, latLng);
              }
            );
          } else {
            removeMarkers();
            removePolyline();
          }
        } else {
          if (newVal && props.randomLatLng && props.selectedLatLng) {
            putMarker(props.randomLatLng);
            putMarker(props.selectedLatLng);
            drawPolyline(props.randomLatLng, props.selectedLatLng);
          } else if (!newVal) {
            removeMarkers();
            removePolyline();
          }
        }
      }
    );

    onMounted(() => {
      if (resultMapRef.value) {
        map = new google.maps.Map(resultMapRef.value as HTMLElement, {
          center: { lat: 37.86926, lng: -122.254811 },
          zoom: 2,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        });
      }
    });

    return {
      resultMapRef,
      onClickNextRoundButton,
      onClickViewSummaryButton,
      onClickPlayAgainButton,
      onClickExitButton,
      endMultiplayerGame,
    };
  },
});
