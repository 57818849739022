import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['result-modal'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['result-modal__map']),
      ref: "resultMapRef"
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['result-modal__footer'])
    }, [
      (!_ctx.isShowingSummary && _ctx.selectedMode === 'single')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style['result-modal__wrapper'])
          }, [
            (_ctx.distance !== null)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(_ctx.$style['result-modal__text'])
                }, "You are " + _toDisplayString(_ctx.distance) + "km away.", 3))
              : _createCommentVNode("", true),
            (_ctx.round < 5)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  class: _normalizeClass(_ctx.$style['result-modal__button--red']),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickNextRoundButton && _ctx.onClickNextRoundButton(...args)))
                }, " NEXT ROUND ", 2))
              : (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: _normalizeClass(_ctx.$style['result-modal__button--red']),
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickViewSummaryButton && _ctx.onClickViewSummaryButton(...args)))
                }, " VIEW SUMMARY ", 2))
          ], 2))
        : _createCommentVNode("", true),
      (!_ctx.isShowingSummary && _ctx.selectedMode === 'multiplayer')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['result-modal__wrapper'])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.distanceByPlayerArr, (item, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: _normalizeClass(_ctx.$style['result-modal__text']),
                  style: {"margin":"4px"},
                  key: index
                }, _toDisplayString(item.playerName) + " is " + _toDisplayString(item.distance) + "km away! ", 3))
              }), 128))
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['result-modal__button-wrapper2'])
            }, [
              (_ctx.round < 5)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: _normalizeClass([
              !_ctx.isOwner && !_ctx.isNextRoundReady
                ? _ctx.$style['result-modal__button--red--disabled']
                : _ctx.$style['result-modal__button--red'],
            ]),
                    disabled: !_ctx.isOwner && !_ctx.isNextRoundReady,
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickNextRoundButton && _ctx.onClickNextRoundButton(...args)))
                  }, " NEXT ROUND ", 10, _hoisted_2))
                : (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['result-modal__button--red']),
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClickViewSummaryButton && _ctx.onClickViewSummaryButton(...args)))
                  }, " VIEW SUMMARY ", 2))
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isShowingSummary && _ctx.selectedMode === 'single')
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.$style['result-modal__wrapper'])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style['result-modal__text'])
            }, "You are " + _toDisplayString(_ctx.score) + "km away in total.", 3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['result-modal__button-wrapper'])
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style['result-modal__button--red']),
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClickPlayAgainButton && _ctx.onClickPlayAgainButton(...args)))
              }, " PLAY AGAIN ", 2),
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style['result-modal__button--brand']),
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onClickExitButton && _ctx.onClickExitButton(...args)))
              }, " EXIT ", 2)
            ], 2)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.isShowingSummary && _ctx.selectedMode === 'multiplayer')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['result-modal__wrapper'])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.multiplayerGameSummary, (item, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: _normalizeClass(_ctx.$style['result-modal__text']),
                  key: index
                }, _toDisplayString(item.playerName) + " is " + _toDisplayString(item.score) + "km away in total.", 3))
              }), 128))
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style['result-modal__button-wrapper2'])
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.$style['result-modal__button--brand']),
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.endMultiplayerGame && _ctx.endMultiplayerGame(...args)))
              }, " EXIT ", 2)
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}