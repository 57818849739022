import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['score-board'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['score-board__wrapper'])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['score-board__label'])
      }, "Map", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['score-board__text'])
      }, _toDisplayString(_ctx.selectedMap), 3)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['score-board__wrapper'])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['score-board__label'])
      }, "Round", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['score-board__text'])
      }, _toDisplayString(_ctx.round) + " / 5", 3)
    ], 2),
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['score-board__label'])
      }, "Score", 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['score-board__text'])
      }, _toDisplayString(_ctx.score), 3)
    ]),
    (_ctx.selectedMode === 'multiplayer')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.$style['score-board__wrapper'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['score-board__label'])
          }, "Time", 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style['score-board__text'])
          }, _toDisplayString(_ctx.countdown), 3)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}