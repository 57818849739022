import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['overlay'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['overlay__container'])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.$style['overlay__text'])
      }, _toDisplayString(_ctx.msg), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['overlay__loader'])
      }, null, 2)
    ], 2)
  ], 2))
}