import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectboxDialog = _resolveComponent("SelectboxDialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      _ctx.state.isHovering
        ? _ctx.$style['select-box-wrapper--on-hover']
        : _ctx.$style['select-box-wrapper'],
      ,
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
        _ctx.state.isHovering
          ? _ctx.$style['select-box--on-hover']
          : _ctx.$style['select-box'],
      ]),
      ref: "selectboxRef",
      onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseOver && _ctx.onMouseOver(...args))),
      onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args))),
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickSelectbox && _ctx.onClickSelectbox(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['select-box__title'])
      }, _toDisplayString(_ctx.title), 3),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['select-box__selected-option'])
      }, _toDisplayString(_ctx.selectedOption.text), 3)
    ], 34),
    _createVNode(_component_SelectboxDialog, {
      isShowingDialog: _ctx.state.isShowingDialog,
      options: _ctx.options,
      onOnChangeOption: _ctx.onChangeOption
    }, null, 8, ["isShowingDialog", "options", "onOnChangeOption"])
  ], 2))
}