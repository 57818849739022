import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isShowingDialog)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.$style['select-box-dialog']),
        style: _normalizeStyle({
      height: _ctx.options.length <= 4 ? _ctx.options.length * 40 + 'px' : '180px',
      bottom:
        _ctx.options.length <= 4 ? (_ctx.options.length * 40 + 16) * -1 + 'px' : '-196px',
    })
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(_ctx.$style['select-box-dialog__option-wrapper']),
            key: option.value,
            onClick: ($event: any) => (_ctx.onChangeOption(option))
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.$style['select-box-dialog__option-text'])
            }, _toDisplayString(option.text), 3)
          ], 10, _hoisted_1))
        }), 128))
      ], 6))
    : _createCommentVNode("", true)
}