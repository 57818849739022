import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style['counter'])
  }, [
    _createElementVNode("button", {
      class: _normalizeClass([
        _ctx.disabledDecrement || _ctx.disabled
          ? _ctx.$style['counter__button--disabled']
          : _ctx.$style['counter__button'],
      ]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args))),
      disabled: _ctx.disabledDecrement || _ctx.disabled
    }, " - ", 10, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style['counter__text-wrapper'])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass([
          _ctx.disabled ? 'counter__text--disabled' : _ctx.$style['counter__text'],
        ])
      }, _toDisplayString(_ctx.count), 3)
    ], 2),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args))),
      class: _normalizeClass([
        _ctx.disabledIncrement || _ctx.disabled
          ? _ctx.$style['counter__button--disabled']
          : _ctx.$style['counter__button'],
      ]),
      disabled: _ctx.disabledIncrement || _ctx.disabled
    }, " + ", 10, _hoisted_2)
  ], 2))
}