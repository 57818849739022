
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    roomNumber: {
      type: String,
      required: true,
    },
    isGameReady: {
      type: Boolean,
      required: true,
    },
  },
});
