<template>
  <div></div>
  <router-view />
</template>

<style lang="scss">
body {
  font-family: "Roboto", sans-serif;
}
</style>
