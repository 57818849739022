
/*global google*/
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
  props: {
    selectedMode: {
      type: String,
      required: true,
    },
    randomLatLng: {
      type: google.maps.LatLng,
      default: null,
      required: false,
    },
    round: {
      type: Number,
      required: true,
    },
    isMakeGuessButtonClicked: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    let map: google.maps.Map;
    const mapRef = ref<HTMLElement>();
    const markers: google.maps.Marker[] = [];

    watch(
      () => props.isMakeGuessButtonClicked,
      (newVal: boolean, oldVal: boolean) => {
        if (newVal && mapRef.value) {
          mapRef.value.style.transform = "translateY(-340px)";
        } else if (!newVal && mapRef.value) {
          mapRef.value.style.transform = "translateY(300px)";
        }
      }
    );

    watch(
      () => props.round,
      (newVal: number, oldVal: number) => {
        if (oldVal + 1 === newVal || (oldVal === 5 && newVal === 1)) {
          removeMarkers();
        }
      }
    );

    const removeMarkers = (): void => {
      markers.forEach((marker, index) => {
        marker.setMap(null);
        markers.splice(index, 1);
      });
    };

    const putMarker = (position: google.maps.LatLng): void => {
      const marker = new google.maps.Marker({
        position: position,
        map: map,
      });
      markers.push(marker);
    };

    const onClickHideMapButton = (): void => {
      context.emit("onClickHideMapButton");
    };

    watch(
      () => props.randomLatLng,
      (newVal: google.maps.LatLng | null) => {
        if (newVal !== null) {
          map.addListener("click", (e: any) => {
            removeMarkers();
            putMarker(e.latLng);
            context.emit("updateSelectedLatLng", e.latLng);
          });
        }
      }
    );

    onMounted(() => {
      if (mapRef.value) {
        map = new google.maps.Map(mapRef.value as HTMLElement, {
          center: { lat: 37.86926, lng: -122.254811 },
          zoom: 1,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        });
      }
    });

    return {
      mapRef,
      onClickHideMapButton,
    };
  },
});
